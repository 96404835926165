@import 'theme';

.custom-radio-container{
  width: 18px;
  height: 18px;
  .custom-radio-before{
    opacity: 0;
    position: absolute;
    width: 18px;
    height: 18px;
    z-index: 50;
    cursor: pointer;
  }

  .custom-radio-after{
    border: 2px solid $app-primary;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    cursor: pointer;
    display: inline-block;
    position: relative;
  }

  .custom-radio-before:active + .custom-radio-after{
    background-color: rgba(128, 128, 128, 0.2);
  }

  .custom-radio-after::after{
    content: '';
    width: 9px;
    height: 9px;
    background-color: $app-primary;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.05s;
  }

  .custom-radio-before:checked + .custom-radio-after::after {
    opacity: 1;
  }

}
