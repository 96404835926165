@import 'theme';

.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 16px 40px 16px 24px;
  z-index: 999;

  .mobile-header-left {
    display: flex;
    align-items: center;
    gap: 24px;
    overflow: hidden;
    button {
      all: unset;
      cursor: pointer;
      display: inline-block;
      width: fit-content;
      height: fit-content;
      letter-spacing: 0;
      text-align: center;
      img {
        display: block;
      }
    }
    .mobile-header-title {
      font-size: 16px;
      font-weight: 600;
      width: 100%;
    }
    .mobile-header-title-with-icon {
      img{
        margin-right: 10px;
      }
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 700;
      color: $app-primary;
      width: 100%;
    }
  }
}
