@import 'theme';

.download-app-first {
  padding: 16px 24px 16px 24px;
  background-color: white;
  border-radius: 10px;
  @include cardShadow();
  margin-bottom: 24px;

  .download-app-first-text {
    color: $text-warning;
    margin-bottom: 16px;
    display: inline-block;
  }

  .google-play-and-app-store {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;

    .download-sv-banner {
      height: 43px;
    }

    .app-store {
      padding: 11px;
      img {
        height: 50px;
      }
    }
    .google-play {
      img {
        height: 72px;
      }
    }
    @media screen and (max-width: 576px) {
      .app-store {
        padding: 8px;
        img {
          height: 32px;
        }
      }
      .google-play {
        img {
          height: 48px;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      // border-radius: 13px;
      img {
        // border-radius: 13px;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .download-app-first {
    .download-app-flexbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      .download-sv-banner {
        height: 32px;
        margin-bottom: 16px;
      }
      .google-play-and-app-store{
        width: 100%;
      }
    }
  }
}
