@import 'theme';

a:link,
a:hover,
a:visited {
  color: $app-primary;
}

.contact-card-container {
  padding: 24px;
  line-height: 21px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .contact-icon {
    position: relative;
    height: 20px;
    margin-right: 6px;
  }

  .contact-card-header {
    margin-bottom: 20px;

    .title-container-div {
      font-size: 18px;
      font-weight: 600;
      color: black;
      align-items: center;

      .title-icon-div {
        height: 100%;
        .card-title-highlight {
          border-radius: 10px;
          border-left: 4px solid $app-secondary;
          height: 100%;
          margin-right: 15px;
        }
        .avatar-container-div {
          img {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
    .view-more-btn {
      color: $app-primary;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .content-container-div {
    flex-wrap: wrap;
    &.flex-row {
      gap: 10px;
    }
    &.flex-column {
      gap: 15px;
    }
    .contact-div {
      align-items: start;
      word-break: break-all;

      .phone.text {
        flex-wrap: wrap;
        white-space: nowrap;
        .phone-divider {
          margin: 0 6px;
          color: rgba(0, 0, 0, 0.5);
          &:last-of-type {
            display: none;
          }
        }
      }
    }
    .contact-type-container {
      gap: 8px;
      .contact-detail-container {
        gap: 15px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-card-container {
    .contact-card-header {
      .title-container-div {
        font-size: 16px;
      }
      .view-more-btn{
        font-size: 14px;
      }
    }
    .content-container-div {
      font-size: 12px;
      word-break: break-all;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
