@import 'theme/Shadow.scss';

.nav-toggle-btn {
  @include cardShadow();

  &:active {
    outline: none;
    box-shadow: none !important;
  }

  &:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.noti-icon-container {
  position: relative;
  .noti-icon-red-dot {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: red;
  }
}

@media screen and (min-width: 577px) and (max-width: 992px) {
  .offcanvas-body {
    padding: 24px 0 24px 0;
    .navbar-nav {
      width: 100%;
      height: 100%;
      .side-bar {
        width: 100%;
        height: 100%;
        box-shadow: none;
      }
    }
  }
}
