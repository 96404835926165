@import 'theme';

.event-application-page-one-info-card{
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  @include cardShadow();
  margin-bottom: 24px;

  label {
    cursor: pointer;
  }

  .event-heading{
    padding: 6px 24px 5px 24px;
    line-height: 24.52px;
  }
  .event-subheading{
    line-height: 21.79px;
    margin-bottom: 8px;
  }
  .event-info-text{
    line-height: 21.79px;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }

  .event-name{
    margin-top: 16px;
  }

  .event-date-and-time{
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .event-date{
      width: 45%;
    }

    .event-time{
      width: 45%;
    }
  }

  .event-application-event-info-price{
    margin-top: 16px;
  }

  .event-applicants{
    margin-top: 16px  ;
    .event-applicants-flexbox{
      display: flex;
      align-items: center;
      justify-content: space-between;

      .member-flexbox{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .checkbox-container{
          width: 16px;
          height: 16px;
        }

        .attendee-number{
          padding: 8px 16px 8px 16px;
          border: 1px solid #CBCBD0;
          border-radius: 30px;
        }
      }

      .guest-flexbox{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .guest-count{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px 8px 16px;
          border: 1px solid #CBCBD0;
          border-radius: 30px;


          button{
            all: unset;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $app-primary;
          }

          input{
            all: unset;
            width: 20px;
            height: 16px;
            text-align: center;
            color: #3d3d3d;
          }
        }

        .attendee-number{
          padding: 8px 16px 8px 16px;
          border: 1px solid #CBCBD0;
          border-radius: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px){
  .event-application-page-one-info-card{
    .event-heading{
      font-size: 18px;
    }
    .event-info-text{
      font-size: 14px !important;
    }

    .event-applicants{
      .event-applicants-flexbox{
        flex-direction: column;
        .member-flexbox{
          width: 100%;
        }
        .guest-flexbox{
          margin-top: 16px;
          width: 100%;
        }
      }
    }
  }
}
