@import 'theme';

.statement-info-row {
  all: unset;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
  margin-bottom: 16px;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: column;

  .member-id-row {
    margin-top: 12px;
    flex: 1;
  }
}
