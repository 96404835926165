.no-ranking-data {
  margin-top: 50%;
  .no-ranking-data-icon {
    margin-right: 24px;
    width: 72px;
    height: 72px;
  }

  .no-ranking-data-text-first {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 576px) {
  .no-ranking-data {
    .no-ranking-data-icon {
      margin-right: 20px;
      width: 58px;
      height: 58px;
    }

    .no-ranking-data-text-first {
      margin-bottom: 0;
      font-size: 12px;
    }

    .no-ranking-data-text-second {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
