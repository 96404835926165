@import 'theme';

.bottom-header {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;

  .bottom-header-shape {
    width: 100vw;
  }

  .bottom-header-middle-button {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 58px;
    width: 58px;
    z-index: 9999;
    bottom: 5%;
    right: calc(50% - 29px);
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;

    .bottom-header-middle-button-logo {
      height: 37px;
      width: 37px;
    }
  }

  .bottom-header-container {
    position: absolute;
    z-index: 999;
    top: 35%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;
    //gap: 50%;
    .bottom-header-container-left {
      width: 35%;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;

      .bottom-header-icon-container {
        position: relative;

        .icon-active-dot {
          position: absolute;
          right: calc(50% - 1.5px);
          bottom: -8.5px;
          width: 3px;
          height: 3px;
        }
      }
    }
    .bottom-header-container-right {
      width: 35%;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;

      .bottom-header-icon-container {
        position: relative;

        .icon-active-dot {
          position: absolute;
          right: calc(50% - 1.5px);
          bottom: -8.5px;
          width: 3px;
          height: 3px;
        }
      }
    }
  }
}
