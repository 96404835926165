@import 'theme';

.no-result-found {
  width: 100%;
  height: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-result-found-first {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 16px;
  }
  .no-result-found-second {
    font-size: 16px;
  }
}
