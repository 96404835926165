@import 'theme';

.event-info-card {
  background-color: #ffffff;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  overflow: visible;
  height: 240px;
  padding: 0;
  @include cardShadow();

  &.selected {
    box-shadow: 0px 0px 0px 3px $app-secondary inset !important;
  }

  .selected-yellow-tick {
    position: absolute;
    top: -12px;
    left: 0px;
    z-index: 10;
  }

  .bookmark {
    all: unset;
    cursor: pointer;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 2px 8px 6px 8px;
    border-radius: 0 10px 0 10px;
    position: absolute;
    @include bookmarkShadow();
  }

  .event-image-container {
    position: relative;
    height: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;
    border-radius: 10px 0px 0px 10px;

    .event-image {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  .eventinfo {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    width: 100%;

    img {
      max-width: 24px;
      max-height: 24px;
    }

    .eventinfo-item {
      margin-top: 12px;
      width: 100%;
      overflow: hidden;
      text-align: left;

      .eventinfo-flexbox {
        width: calc(100% - 32px);
        div {
          text-align: left;
          width: 100%;
        }
      }

      .payment-item {
        white-space: nowrap;
      }
    }

    .eventinfo-last-item {
      margin-top: 12px;
    }

    .title-text {
      max-width: 95%;
    }

    .member-only {
      text-align: left;
      line-height: 19.07px;
    }

    .flag-text,
    .warning-text {
      margin-left: 6px;
    }

    .warning {
      margin-right: 14px;
    }
  }

  .placeholder-glow {
    width: 100%;
    display: block;
    span {
      height: 100%;
      width: 100%;
    }
  }

  .event-info-card-image-placeholder {
    height: 100%;
    display: block;
    span {
      display: block;
    }
  }

  .event-info-card-text-placeholder {
    width: 150px;
  }
  .event-info-card-title-placeholder {
    width: 300px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 576px) {
  .event-info-card {
    height: fit-content;
    flex-direction: column;
    .event-image-container {
      width: 100%;
      border-radius: 10px;
      aspect-ratio: 344/170;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
