@import 'theme';

.stepper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .stepper-step{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 48px;

    .stepper-button{
      all: unset;
      text-align: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #948CF6;
      border: 3px solid $app-primary;
      background-color: transparent ;
    }

    .active{
      background-color: #948CF6;
      color: white;
    }

    .stepper-label{
      margin-top: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16.34px;
    }
  }

  .stepper-line{
    height: 3px;
    background-color: $text-secondary;
    flex-grow: 100;
    margin: 0 9px 0 9px;
    border-radius: 10px;
  }
}
