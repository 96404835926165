.drive-private-dialog {
  width: 374px;

  .dialog-input-div {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .drive-private-dialog{
    width: auto;
  }
}
