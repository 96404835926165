@import 'theme';

.form-group {
  width: 100%;

  .input-text {
    color: black;
    position: absolute;
    z-index: 999;
    top: calc(50% - 12px);
    left: 16px;
  }

  &.input-item {
    height: 85px;
  }

  &.textarea-item {
    height: fit-content;
  }

  label {
    margin-bottom: 8px;
    color: $text-primary;
  }

  @media (max-width: 576px) {
    label {
      font-size: 14px;
    }
  }

  .required-symbol-div {
    position: absolute;
    right: -8px;
    top: -4px;
  }

  .label-placeholder-div {
    letter-spacing: 0.03em;
    line-height: 22px;
  }

  .input-div-wrapper {
    position: relative;

    input {
      border: 1px solid #d9d9d9;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.03));
      border-radius: 10px;
      height: 40px;
      padding: 8px 48px 8px 16px;
      color: $text-primary;
      @include typography(14px, medium);
      position: relative;

      &:focus {
        outline: none !important;
        border: 2px solid $app-primary;
        box-shadow: none !important;
      }

      @media (max-width: 576px) {
        height: 35px;
      }
    }

    textarea {
      padding: 8px 16px;
      color: $text-primary;
      @include typography(14px, medium);
      position: relative;
      height: 120px;
    }

    .cancel-input-btn-div {
      cursor: pointer;
      position: absolute;
      right: 17px;
      // top: 7px;
      top: calc(50% - 10px);
      height: 20px;
      img {
        vertical-align: top;
      }
    }
  }

  .select-div-wrapper {
    .select2-selection__control {
      border-radius: 10px;

      .select2-selection__value-container {
        .select2-selection__placeholder,
        .select2-selection__single-value {
          padding-left: 8px;
        }
      }

      .select2-selection__indicators {
        span {
          display: none;
        }

        .select2-selection__indicator {
          margin-right: 9px;
          color: #948cf6;
        }
      }
    }
  }
}
