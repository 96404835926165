.file-view-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  position: relative;
  z-index: 1;

  .file-viewer-tool-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 0px 20px;
    z-index: 1;

    .file-title {
      width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 700px) {
      .file-title {
        width: 250px;
      }
    }

    .file-view-tool-bar-btn {
      width: 50px;
      height: 50px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      color: white;
      border: 0px;
      svg {
        width: 30px;
        height: 40px;
      }
    }
  }

  .file-view-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .swiper-container {
      width: 100%;
      height: calc(100% - 100px);
      position: relative;

      .swiper {
        height: 100%;

        .swiper-slide {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;

          .file-pdf {
            height: 100%;
            overflow-y: hidden;
            color: white;

            .pdf-iframe {
              width: 100%;
              min-width: 700px;
              min-height: 90vh;
            }

            @media (max-width: 700px) {
              .pdf-iframe {
                width: 100vw;
                min-width: 100%;
                height: 100vh;
              }
            }
          }
          .file-image-non-desktop {
            max-height: 100%;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              position: relative;
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
            }
          }

          .file-image,
          .file-video,
          .file-default {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            overflow: hidden;

            video {
              max-width: 100%;
            }

            figure {
              width: 100%;
              height: 100%;
              position: relative;

              div {
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .iiz__img {
                  position: relative;
                  max-width: 100%;
                  max-height: 100%;
                  width: fit-content;
                  height: fit-content;
                  object-fit: contain;
                }

                // img {
                //   position: relative;
                //   width: 100%;
                //   height: 100%;
                //   object-fit: contain;
                // }
              }

              .iiz__btn.iiz__hint {
                display: none;
              }

              .iiz__btn.iiz__close.iiz__close--visible {
                display: none;
              }
            }
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.3);
          }

          &:after {
            color: white;
            font-size: 30px;
          }
        }

        .swiper-button-disabled {
          cursor: not-allowed;
          display: none;
        }
      }
    }
  }
}
