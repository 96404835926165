@import 'theme';

.slider-card {
  background-color: #ffffff;
  color: $text-primary;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  @include cardShadow();
  width: 350px;
  // height: 440px;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;

  .slide-image-container {
    position: relative;
    width: 100%;
    object-fit: cover;

    .loading-slide-image {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      border-radius: 10px 20px 10px 10px;
      flex: 1;
      display: inline-block;

      span.placeholder {
        height: 100%;
      }
    }

    .slide-image {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      border-radius: 10px 20px 10px 10px;
      flex: 1;
    }

    .booked {
      top: 4px;
      left: 8px;
      padding: 5px 18px 5px 18px;
      background-color: #92ca62;
      color: white;
      font-size: 10px;
      border-radius: 15px;
    }

    .bookmark {
      all: unset;
      cursor: pointer;
      z-index: 1;
      top: 0;
      position: absolute;
      right: 0;
      padding: 2px 8px 6px 8px;
      border-radius: 0 0 0 10px;
    }
  }

  .slide-info-contaienr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    // height: 240px;
    flex-grow: 1;

    .title-text {
      width: 100%;
      height: 21px;
    }

    .member-only,
    .courseID {
      line-height: 19.07px;
    }

    .warning {
      margin-right: 14px;
    }

    .warning-text,
    .flag-text {
      margin-left: 6px;
    }

    .eventinfo-item {
      margin-top: 12px;
    }
  }
}
@media screen and (max-width: 576px) {
  .slider-card {
    width: 280px;
    // height: fit-content;
  }
}
