@import 'theme';

.list-item-row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  //   background: red;

  td {
    display: flex;
    width: 100%;
    border-bottom-width: 0px;
    border-radius: 10px 0px 0px 10px;
    padding: 0;
    &:hover {
      background: $dialog-light-orange;
      border-radius: 10px 0px 0px 10px;
      cursor: pointer;
    }

    .drive-folder-item-div {
      .image-preview-div {
        user-select: none;
      }
      .file-name-div {
        text-align: left;
        margin-left: 20px;
        user-select: none;
        width: 70%;
        word-break: break-all;
        display: flex;
        align-items: center;
        @media screen and (min-width: 576px) {
          height: 56px;
        }
      }
    }

    .drive-non-selected-item-btn-div {
      .bookmark-div {
        user-select: none;
        margin-right: 20px;
      }
    }
  }

  .file-list-item-bookmark-container {
    position: relative;
    user-select: none;
    margin-right: 20px;
    background-color: transparent;
    .bookmark {
      all: unset;
      cursor: pointer;
      position: static;
      background-color: transparent !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .list-item-row {
    padding: 8px 0;
    td {
      .drive-folder-item-div {
        .image-preview-div {
          .file-image-div {
            display: flex;
            align-items: center;
            img {
              height: 20px;
              width: 20px;
            }
          }
        }
        .file-name-div {
          margin-left: 12px;
          max-height: 45px;
        }
      }

      &:hover {
        background: none;
        border-radius: 10px 0px 0px 10px;
        cursor: pointer;
      }

      img,
      button {
        padding: 0;
        margin: 0;
      }
    }
  }
}
