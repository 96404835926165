@import 'theme';

.avatar{
  .rounded-circle{
    span{
      border-radius: 50%;
      display: block;
    }
  }
}
