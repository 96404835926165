.dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  z-index: 999;

  .fullscreen {
    width: 100%;
    height: 100%;

    .image-preview-container {
      width: 100%;
      height: 100%;
      position: relative;

      .image-preview-tool-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.65);
        padding: 0px 20px;
        z-index: 1;

        .image-preview-tool-bar-btn {
          width: 100%;
          height: 50px;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          border: 0px;

          svg {
            color: white;
            font-size: 30px;
          }
        }
      }

      .image-preview-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .swiper-container {
          width: 100%;
          height: 90%;
          position: relative;

          .swiper {
            height: 100%;

            .swiper-slide {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: relative;
            }

            .swiper-button-prev,
            .swiper-button-next {
              &:hover {
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.3);
              }

              &:after {
                color: white;
                font-size: 30px;
              }
            }

            .swiper-button-disabled {
              cursor: not-allowed;
              display: none;
            }
          }
        }
      }
    }
  }
}
