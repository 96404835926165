@import 'theme';

.function-div-grid {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;

  .show-selected-number-div {
    display: flex;
    align-items: center;
    .selected-text-div {
      margin-right: 5px;
    }
  }

  .first-tier-function-div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    line-height: 40px;

    .selected-div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .add-to-my-folder-div {
        position: absolute;
        right: -10px;

        img {
          margin-right: 10px;
        }
      }
    }

    .private-selected-div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .private-move-folder-div,
      .private-rename-folder-div,
      .private-delete-seleced-div {
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;

        img {
          margin-right: 5px;
        }
      }

      .private-move-folder-div,
      .private-rename-folder-div {
        margin-right: 45px;
      }
    }

    .search-bar {
      width: 100%;
      text-align: end;
      position: relative;
    }

    .selection-div {
      width: 7%;
      margin-left: 5px;
      text-align: end;

      .text-div {
        width: 40px;
        margin-left: auto;
      }
    }
  }
  .searching-div {
    position: absolute;
    background-color: white;
    width: calc(100%);
    min-height: 200px;
    z-index: 100;
    top: 50px;
    right: 0;
    border-radius: 10px;
    @include cardShadow();
  }
}

@media screen and (max-width: 576px) {
  .function-div-grid {
    overflow: hidden;
    .first-tier-function-div {
      height: fit-content;
      justify-content: space-around;

      .selection-div {
        width: auto;
      }
      .private-selected-div {
        justify-content: space-around;
        .private-move-folder-div,
        .private-rename-folder-div{
          margin: 0;
        }
      }
    }
  }
}
