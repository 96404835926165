@import 'theme';

.dropdown {
  .dropdown-toggle {
    box-shadow: none;
    border: 0;
    border-radius: 0px;

    &:hover {
      background: none;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 24px 0px;
    width: 228px;
    height: 240px;

    a.dropdown-item {
      .drop-down-item {
        padding: 8px 10px;

        img {
          margin-right: 12px;
        }
      }
      &:hover {
        background: $dialog-light-orange;
      }
    }
  }
}
