@use 'sass:map';

$font-weights: (
  'medium': 500,
  'semibold': 600,
  'bold': 700,
);

// e.g. @include typography(12px, regular);
@mixin typography(
  $font-size,
  $font-weight,
  $text-align: unset,
  $white-space: pre-wrap
) {
  // @include font-size($font-size);
  font-size: $font-size;
  font-family: 'Noto Sans', sans-serif;
  line-height: $font-size * 1.4;
  font-weight: map.get($font-weights, $font-weight);
  text-align: $text-align;
  white-space: $white-space;
  letter-spacing: 0;
}

@mixin typographyImportant(
  $font-size,
  $font-weight,
  $text-align: unset,
  $white-space: pre-wrap
) {
  // @include font-size($font-size);
  font-size: $font-size !important;
  font-family: 'Noto Sans', sans-serif;
  line-height: $font-size * 1.4;
  font-weight: map.get($font-weights, $font-weight);
  text-align: $text-align;
  white-space: $white-space;
  letter-spacing: 0;
}
