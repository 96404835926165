@import './Variables.scss';
@import './Shadow.scss';
@import './Button.scss';

.yellow-vector{
  height: 17px;
  width: 3px;
  background: $app-secondary;
  border-radius: 20px;
  content: '';
}

.yellow-vector-none {
  display: none !important;
}

.yellow-vector-red {
  background: $text-warning !important;
}


.yellow-heading{
  background-color: $app-secondary;
  border-radius: 30px;
  width: fit-content;
  color: white;
  display: inline-block;
  font-weight: 600;
  white-space: nowrap;
  padding: 6px 24px 5px 24px;
  font-size: 18px;
}


//Button for error page
.return-to-home-link {
  @include purpleButton();
  height: 44px;
  font-weight: 600;
  line-height: 24.52px;
  font-size: 18px;
  width: 372px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 576px) {
    width: 300px;
  }
}
