@import 'theme';
.drive-list-item-mobile {
  width: 150px;
  position: relative;

  .drive-list-item-mobile-tick {
    @include tickShadow();
    position: absolute;
    top: -10px;
    left: -10px;
    z-index: 99;
    border-radius: 50%;
  }

  .drive-list-item-mobile-image-container {
    position: relative;
    width: 100%;
    background-color: white;
    // overflow: hidden;
    border-radius: 10px;
    @include cardShadow();

    .drive-list-item-mobile-bookmark-button {
      @include bookmarkShadow();
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
      height: 32px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 0 10px 0 10px;
      button {
        all: unset;
        cursor: pointer;
      }
    }

    .drive-list-item-mobile-image {
      overflow: hidden;
    }
  }

  .drive-list-item-mobile-image-container-selected{
    box-shadow:0px 0px 0px 3px $app-secondary  !important;
  }

  .drive-list-item-mobile-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    // overflow: hidden;
    padding-top: 12px;
    .drive-list-item-mobile-title {
      font-size: 14px;
    }
    .drive-list-item-mobile-dropdown {
      button{
        padding: 0;
      }
    }
  }
}
