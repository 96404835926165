@import 'theme';
.disabled-user-card {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 6px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  .disabled-user-card-text {
    font-size: 24px;
    font-weight: 600;
    color: white;
  }
  .disabled-user-card-button {
    font-weight: 600;
    font-size: 16px;
    all: unset;
    cursor: pointer;
    color: white;
    background-color: $app-primary;
    padding: 11px 88px;
    border-radius: 10px;
  }
}

@media screen and (max-width: 576px) {
  .disabled-user-card {
    .disabled-user-card-text {
      font-size: 16px;
    }
    .disabled-user-card-button {
      padding: 11px 44px;
    }
  }
}
