@import 'theme';

.drive-search-result-container {
  position: relative;
  padding-top: 16px;

  .is-search-loading-div {
    // position: absolute;
    height: 100px;
    // left: 0;
    // right: 0;
    // top: 200px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .drive-type-menu-div {
    margin: 0 24px 0 24px;
    margin-bottom: 20px;
  }

  .search-result-div{
    max-height: 400px;
    overflow: auto;
    margin: 0 16px 0 16px;
  }

  .search-result-group-div {
    margin-bottom: 20px;

    .content-div {
      .title-div {
        margin-left: 8px;
        position: relative;
        margin-bottom: 20px;
        text-align: left;

        .title-bottom-bar {
          width: 60px;
          height: 4px;
          position: absolute;
          background: $app-secondary;
          bottom: -8px;
          left: 1px;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .drive-search-result-container{
    padding-top: 0;
    .search-result-group-div{
      .content-div{
        .title-div{
          margin-left: 0;
          margin-bottom: 15px;
          font-size: 16px;
          .title-bottom-bar {
            width: 22px;
            height: 4px;
            position: absolute;
            background: $app-secondary;
            bottom: -8px;
            left: 0;
            border-radius: 10px;
          }
        }
      }
    }
    .drive-type-menu-div{
      margin: 0 0 27px 0;
    }
    .search-result-div{
      max-height: none;
      margin: 0;
    }
  }
  .drive-search-result-container-stick{
    overflow: unset;
    padding: 0 16px 0 16px;
    .drive-type-menu-div{
      margin: 0 0 22px 0;
      padding: 0 0 5px 0;
      position: sticky;
      top: 0;
      z-index: 60;
      background-color: white;
    }
    .search-result-div{
      max-height: none;
      margin: 0;
    }
  }
}
