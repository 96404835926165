@import 'theme/index.scss';

.dialog-overlay {
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.dialog-modal {
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  // border-radius: 4px;
  outline: none;
  padding: 20px;
  border-radius: 10px;
  max-width: 100%;

  &.sm {
    width: 300px;
    inset: unset;
  }

  &.md {
    width: 500px;
    inset: unset;
  }

  &.lg {
    width: 1200px;
    inset: unset;
  }

  &.xl {
    inset: 40px;
    padding: 0px;
  }

  &.fullscreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    inset: 0px;
  }

  &.w-580 {
    width: 580px;
    height: 280px;
    inset: unset;
  }

  &.w-700 {
    width: 700px;
    height: 780px;
    inset: unset;
  }

  &.w-800 {
    width: 800px;
    height: 880px;
    inset: unset;
  }

  .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff;

    .modal-title {
      line-height: 38px;
    }

    .modal-close-btn {
      position: absolute;
      right: 42px;
      top: 16px;

      border: none;
      outline: none;
      background-color: transparent;
      font-size: 24px;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      color: #000000;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }

      &:active {
        outline: none;
      }
    }
  }

  .modal-footer {
    // display: flex;
    // justify-content: flex-end;
    // align-items: center;
    // padding-top: 20px;
    // border-top: 1px solid #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      all: unset;
      cursor: pointer;
      color: $app-primary;
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      width: 160px;
      height: 40px;
      text-align: center;

      &.cancel {
        border: 1px solid #f4ecff;
      }

      &.confirm {
        background: linear-gradient(
          to right,
          #ede3f7 0%,
          #fdf9ff 50%,
          #f1eaf9 100%
        );
      }
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    border: unset !important;
    margin-bottom: 24px;

    .button-div {
      text-align: center;

      button {
        @include customBtn;
        background: linear-gradient(90deg, #f2e8ff, #fbf7ff, #f7f2ff);

        width: 300px;
        height: 44px;
        color: $app-primary;
        font-weight: 600;
        font-family: 'Noto Sans', sans-serif;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 9px;
        max-width: 100%;
      }
    }
  }
}

.custom-modal {
  width: 500px;
  height: 500px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 100 ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media screen and (max-width: 576px) {
  .dialog-modal {
    .modal-content {
      padding: 0;
    }
  }
}
