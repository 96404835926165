@import 'theme';

.event-bookmark-page {
  .event-bookmark-page-top {
    margin-bottom: 36px;
  }
  .event-bookmark-page-topright {
    height: 24px;

    button {
      all: unset;
      cursor: pointer;
    }
    .select,
    .cancel,
    .remove-all-selected {
      color: $app-primary;
    }

    .remove-all-selected-icon {
      margin-right: 4px;
    }
  }
  .event-bookmark-page-body {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 24px;
    column-gap: 36px;
    @media screen and (max-width: 1400px) {
      column-gap: 24px;
    }
    a {
      color: $text-primary;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .event-bookmark-page {
    .event-bookmark-page-top {
      margin-bottom: 36px;

      .event-bookmark-page-topright {
        height: 24px;

        button {
          all: unset;
          cursor: pointer;
        }
        .select,
        .cancel,
        .remove-all-selected {
          color: $app-primary;
        }

        .remove-all-selected-icon {
          margin-right: 4px;
        }
      }
    }

    .event-bookmark-page-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      a {
        color: $text-primary;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 1399px) and (min-width: 577px) {
  .event-bookmark-page{
    .event-bookmark-page-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      a {
        color: $text-primary;
        text-decoration: none;
      }
    }
  }
}
