@mixin cardShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
}

@mixin boxShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}

@mixin bookmarkShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}

@mixin bottomPartShadow {
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.04);
}

@mixin buttonShadow {
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
}

@mixin tickShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
