.Toastify__toast--warning {
  background: #ffe8bc !important;
}
.Toastify__toast--error {
  .toastBody {
    font-family: 'Noto Sans', sans-serif;
    padding-left: 4px;
    font-weight: 600;

    .Toastify__toast-icon {
      width: 30px;
      margin-right: 4px;
      color: red;
      svg {
        font-size: 23px;
      }
    }
  }
}

.Toastify__toast--success {
  .toastBody {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
  }
}

.Toastify__close-button {
  position: absolute;
  right: 10px;
  top: 20px;
  box-shadow: none;
}
@media screen and (max-width: 576px) {
  .Toastify__toast{
    width: calc(100vw - 40px);
    padding-right: 40px;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
}
