@import 'theme/Shadow.scss';

.user-info-card {
  width: 100%;
  background-color: #ffffff;
  @include cardShadow();
  border-radius: 25px;
  padding: 24px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 576px) {
  .user-info-card {
    border-radius: 25px;
    padding: 16px 12px;
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}

