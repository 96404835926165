@import 'theme';

.rank-item {
  .rank-item-seperation-line {
    margin-top: 18px;
    margin-bottom: 18px;
  }

  .rank-item-info {
    padding-top: 20px;

    .rank-item-name-flexbox {
      max-width: 60%;
      overflow: hidden;
      .rank-item-number {
        width: 50px;
        line-height: 24.52px;
      }
      .rank-item-name {
        line-height: 24.52px;
        max-width: calc(100% - 50px);
      }
    }

    .rank-item-amount{
      max-width: 30%;
    }
  }
}

@media screen and (max-width: 768px) {
  .rank-item {
    .rank-item-info {
      .rank-item-number {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
  .rank-item {
    .rank-item-info {
      .rank-item-number {
        font-size: 18px;
      }
    }
  }
}
