@import 'theme';

.attendee-info-card {
  padding: 24px;
  @include cardShadow();
  background-color: white;
  border-radius: 10px;
  margin-top: 24px;
  color: $text-primary;

  .attendee-info-heading {
    padding: 6px 24px 5px 24px;
    margin-bottom: 16px;
    line-height: 24.52px;
  }

  .attendee-info {
    .attendee-name,
    .attendee-email,
    .attendee-contact {
      width: 33%;

      .attendee-info-text {
        line-height: 21.79px;
        margin-top: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }
  }
}
