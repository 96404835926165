.ranking-body {
  margin: 10px;

  .ranking-top3-container {
    margin: 3%;
    margin-top: 130px;
  }

  .list-ranking {
    min-height: 551px;

    .list-ranking-placeholder {
      span {
        width: 100%;
        height: 28px;
        margin-top: 20px;
        margin-bottom: 36px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ranking-body {
    margin: 10px 0 10px 0;

    .ranking-top3-container {
      margin-top: 48px;
    }

    .list-ranking {
      min-height: 0;

      .list-ranking-placeholder {
        span {
          height: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
  .ranking-body {
    .list-ranking {
      .list-ranking-placeholder {
        span {
          height: 22px;
        }
      }
    }
  }
}
