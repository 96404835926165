@import 'theme';

.price-text{
  flex-wrap: wrap;

}

@media screen and (max-width: 576px) {
  .price-text{
    font-size: 14px;
    img{
      width: 20px;
    }
  }
}
