@import 'theme';
.event-total-price-container {
  width: 40%;
  margin-right: 0;
  margin-left: auto;

  .total-price-flexbox {
    .total-price-text {
      color: $text-primary;
      font-size: 16px;
      line-height: 18.75px;
      font-weight: 600;
    }

    .total-price {
      color: $app-primary;
      font-weight: 700;
      font-size: 22px;
      line-height: 21px;
    }
  }
  .points-earn-after-apply {
    color: $text-secondary;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 576px) {
  .event-total-price-container {
    width: 100%;
    img {
      width: 20px;
    }
    .total-price-flexbox {
      .total-price {
        font-size: 20px;
      }
    }
  }
}
