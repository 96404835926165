@import 'theme';

.event-application-detail-body {
  .attendee-info-cards-container {
    .attendee-info-card:first-child {
      margin-top: 0;
    }
  }

  .event-application-detail-infocard-container {
    .event-application-detail-seperation-line {
      margin-bottom: 24px;
      margin-top: 24px;
    }

    .event-application-detail-infocard {
      background-color: white;
      border-radius: 10px;
      padding: 24px 24px 24px 24px;
      @include cardShadow();

      .event-application-detail-info {

        .event-application-detail-attendee-count {
          .attendee-count-text {
            max-width: 45%;

            .attendee-number {
              padding: 8px 16px 8px 16px;
              border: 1px solid #cbcbd0;
              border-radius: 30px;
            }
          }
        }

        .event-application-fee {
          max-width: 35%;
          margin-left: auto;
          margin-right: 0;

          .amount-paid {
            color: $app-primary;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .event-application-detail-body {
    .please-show-QR {
      margin-bottom: 16px;
    }

    .QR-swiper-container {
      .swiper-slide {
        transform: scale(0.8);
        align-self: center;
      }

      .swiper-slide-active {
        transform: scale(1);
      }
    }
    .event-application-detail-infocard-container {
      .event-application-detail-infocard {
        .event-application-detail-info {
          .event-application-detail-attendee-count {
            .attendee-count-text {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
