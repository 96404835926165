@import 'theme/index.scss';

.modal-dialog {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
}

.qrcode-modal {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: $app-secondary;
  }

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0 !important;
    position: absolute;
    .swiper-pagination-bullet {
      background-color: grey;
      --swiper-pagination-bullet-size: 6px !important;
    }

    .swiper-pagination-bullet-active {
      --swiper-pagination-bullet-size: 10px !important;
      background-color: $app-secondary;
    }
  }
}

#qrcode-modal {
  padding-bottom: 35px;

  .modal-header {
    border-bottom: 0;
    color: $app-primary;
    font-weight: 600;
    padding-left: 40px;
    padding-bottom: 5px;
    padding-top: 10px;
  }

  .qr-code-modal-content {
    .qrcodes-swiper {
      width: 100%;
      // height: 400px;
      padding: 10px 0;

      .swiper-wrapper {
        position: relative;

        .qr-code-card-slide {
          display: flex;
          align-items: center;
          width: 300px;
          border: 0.2px solid rgba(203, 203, 208, 1);
          border-radius: 10px;
          box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);

          // .QRcode-card{
          //   padding-bottom: auto;
          // }
          .QRcode-card {
            box-shadow: none;
          }
        }
      }
    }

    .QRcode-container {
      width: 238px;
    }
  }
}

#user-qrcode-modal,
.user-qrcode-modal {
  padding: 0;
}

.modal-content:has(.user-qrcode-modal) {
  width: fit-content;
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 0;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}
