@import 'theme';

.breadcrumb-div {
  word-break: break-word;
  display: flex;
  overflow: hidden;
  nav {
    overflow: hidden;
    flex-grow: 1;
    ol {
      overflow: hidden;
      flex-grow: 1;
      margin: 0;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    background-image: url(../../../icons/breadcrumb_seperator.svg);
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    content: '';
    max-height: 22px;
  }
  a {
    font-weight: 700;
    color: $app-primary;
  }

  .breadcrumb-item {
    white-space: nowrap;
    max-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .breadcrumb-item.active {
    font-weight: 700;
    color: $text-disabled;
  }
}

@media screen and (max-width: 576px) {
  .breadcrumb-div {
    nav{
      ol{
        font-size: 12px;
        line-height: 16px;
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      background-image: url(../../../icons/breadcrumb_seperator.svg);
      background-size: 16px 16px;
      display: inline-block;
      width: 16px;
      height: 16px;
      content: '';
    }
  }
}
