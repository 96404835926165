@import 'theme';

.contact-detail-page-container {
  .contact-detail-page-breadcrumb {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 576px) {

}
