$app-primary: #6b63c4;
$app-primary-dark: #4a488f;
$app-bg-primary: #c6c1ff;
$app-secondary: #ffba5e; // #FFA021?
$app-secondary-dark: #d19329;
$header-title: #ffa021;

$text-primary: #3d3d3d;
$text-secondary: #d9d9d9;
$text-disabled: #b2aeb5;
$text-warning: #f04c4d;
$text-link: #0f18ef;

$app-bg: #fafafa;
$dialog-light-orange: rgba(255, 186, 94, 0.21);

$side-bar-width: 276px;
$nav-bar-height: 90px;
$bottom-sheet-height: 92px;
$content-container-margin: 24px;

$page-margin-top: 24px;
$page-margin-bottom: 24px;

$mobile-page-padding-bottom: 100px;
$mobile-page-padding-top: 64px;
$mobile-page-horizontal-padding: 8px;
$mobile-header-padding-top: 56px;
$mobile-page-margin-top: 16px;
$mobile-tabbar-height: 57px;
