.outstanding-amount-div {
  padding: 34px 36px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .outstanding-amount-text {
    display: flex;
    flex-direction: column;

    .left-side {
      margin-right: 48px;
    }
  }

  .button-div {
    width: 158px;
    height: 44px;
    text-align: center;

    .pay-btn:disabled {
      opacity: 0.5;
    }
  }

  @media (max-width: 992px) {
    padding: 15px 20px;
    align-items: center;

    .outstanding-amount-text {
      align-items: flex-start;

      .left-side {
        margin-left: 0;
        margin-right: 20px;

        >div {
          font-size: 14px;
        }
      }

      .text-color-secondary {
        font-size: 12px;
      }
    }

    .button-div {
      width: 100px;

      button {
        font-size: 16px;
        padding: 8px 0;
      }
    }
  }
}
