@import './Variables.scss';
@import './Shadow.scss';

html {
  overflow-anchor: none;
  scroll-behavior: auto !important;
}

:root {
  --default-text-color: $text-primary;
}

/* Apply the CSS variable to the body element */
body {
  color: var(--default-text-color);
}

html,
body {
  font-family: 'Noto Sans', sans-serif;
  color: $text-primary !important;
  background-color: $app-bg;
}

body {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  color: #0f18ef;
  letter-spacing: 0.03em;
  text-decoration: none;
}

button {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

button.clean-btn {
  border: unset;
  background-color: transparent;
  padding: 0px;
  box-shadow: unset;
}

.card {
  @include cardShadow();
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid transparent;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.page-container {
  @media screen and (min-width: 577px) {
    margin-top: $page-margin-top;
    padding-bottom: $page-margin-bottom;
  }

  @media (max-width: 576px) {
    // mobile-padding
    padding-left: $mobile-page-horizontal-padding;
    padding-right: $mobile-page-horizontal-padding;
  }
}

.page-container-with-header {
  @media screen and (min-width: 577px) {
    margin-top: $page-margin-top;
    padding-bottom: $page-margin-bottom;
  }
  @media screen and (max-width: 576px) {
    padding-top: calc($mobile-header-padding-top + $mobile-page-margin-top);
    padding-left: $mobile-page-horizontal-padding;
    padding-right: $mobile-page-horizontal-padding;
  }
}

.page-container-with-header-and-tabbar {
  @media screen and (min-width: 577px) {
    margin-top: $page-margin-top;
    padding-bottom: $page-margin-bottom;
  }
  @media screen and (max-width: 576px) {
    padding-top: calc(
      $mobile-header-padding-top + $mobile-page-margin-top +
        $mobile-tabbar-height
    );
    padding-left: $mobile-page-horizontal-padding;
    padding-right: $mobile-page-horizontal-padding;
  }
}

.drive-page-container {
  padding-left: 28px;
  padding-right: 28px;
}

.font-spacing {
  letter-spacing: 0.03em;
}

@mixin round-title-div {
  width: 204px;
  height: 65px;
  background: #ffba5e;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.drag-none {
  -webkit-user-drag: none;
}

.cursor-pointer {
  cursor: pointer;
}

.unselectable {
  cursor: not-allowed !important;
}

.disable-color-for-svg {
  filter: brightness(0.5) invert(1);
}

.disable-box-border {
  border: 1px solid #d9d9d9;
}

.disable-color {
  color: #ddd !important;
  filter: brightness(0.5) invert(1);
  cursor: not-allowed !important;
}

.error-page-withUI {
  height: 824px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 576px) {
    height: calc(100vh - 172px);
    margin-top: 56px;
  }
}

.one-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

// .one-line-ellipsis-textfit {
//   div {
//     white-space: nowrap;
//     overflow: hidden;
//     word-break: break-all;
//     text-overflow: ellipsis;
//   }
// }

.two-line-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  // word-break: break-all;
  //fallback if the browser doesnt support the code below

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    // word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.disabled-button {
  color: $text-secondary !important;
  @include buttonShadow();
  background: white !important;
  cursor: default;
}

.display-none {
  display: none;
}

// .two-line-ellipsis-textfit {
//   div {
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     //fallback if the browser doesnt support the code below

//     @supports (-webkit-line-clamp: 2) {
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: initial;
//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//     }
//   }
// }

// .ql-editor .ql-video {
//   width: 100% !important;
//   aspect-ratio: 16/9 !important;
// }
