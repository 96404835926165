@import 'theme';

.event-slider {
  padding: 20px 0px !important;
  overflow-x: hidden;
  .swiper-slide{
    width: auto;
    height: auto;
  }
}
