@import 'theme';

.event-detail-body {
  @include cardShadow();
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 24px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  .bookmark {
    all: unset;
    cursor: pointer;
    z-index: 2;
    top: 0;
    right: 0;
    padding: 8px 16px 8px 16px;
    border-radius: 0 0 0 10px;
    opacity: 1;
    position: absolute;
    @include bookmarkShadow();
  }

  .event-detail-image {
    border-radius: 10px 20px 10px 10px;
    padding-bottom: 50px;
    overflow: hidden;

    .event-detail-image-container {
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 10px 10px 10px 10px;
      overflow: visible;

      .swiper {
        height: 100%;
        position: relative;
        overflow: visible;
      }

      img {
        width: 100%;
        height: 100%;
        z-index: 5;
        object-fit: cover;
        border-radius: 10px;
      }
      .swiper-pagination-bullet {
        --swiper-pagination-bullet-size: 6px !important;
      }

      .swiper-pagination-bullet-active {
        --swiper-pagination-bullet-size: 10px !important;
      }
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      --swiper-pagination-color: #ffba5e;
      z-index: 50;
      bottom: -24px !important;
      position: absolute;
    }
  }

  .event-detail-info {
    padding: 0 24px 0 24px;

    .event-title {
      line-height: 27.24px;
      color: $app-secondary;
      overflow: hidden;
      max-width: 100%;
      word-wrap: break-word;
    }

    .member-only-and-availability,
    .event-date,
    .event-time,
    .event-avenue,
    .event-fee,
    .event-deadline,
    .event-contact {
      margin-top: 18px;
      max-width: 100%;
    }

    .info-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 21.79px;
      margin-left: 8px;
      word-break: break-word;
      overflow: hidden;
      max-width: 100%;
    }

    .payment-text {
      display: flex;
      align-items: center;
    }

    .availability-text {
      color: $text-warning;
      font-weight: 700;
      font-size: 14px;
      line-height: 19.07px;
      margin-left: 4px;
    }

    .points-to-be-earn-when-apply-container {
      margin-top: 18px;
      gap: 7px;
      .points-to-be-earn-when-apply-text {
        color: $app-primary;
        font-weight: 700;
        font-size: 16px;
        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
      }
    }

    .description-content {
      margin-top: 18px;
      .ql-container.ql-snow {
        border: none !important;
      }
      .ql-editor {
        word-break: break-word;
        padding: 0;
      }
    }
  }

  .course-detail-info {
    padding: 0 24px 0 24px;
    .seperation-line {
      border-top: 1px solid $text-secondary;
      margin: 25px 0 24px 0;
    }

    .course-detail-info-heading {
      .course-detail-info-heading-text {
        line-height: 24.52px;
      }
      .course-detail-info-heading-count {
        line-height: 21px;
      }
    }

    .course-detail-info-body {
      .course-schedule {
        max-width: 45%;
        margin-top: 16px;
        // .course-schedule-date{
        //   text-align: center;
        // }
        .course-schedule-date,
        .course-number,
        .course-schedule-time {
          width: 33%;
          flex-grow: 1;
        }
        @media screen and (max-width: 1200px) {
          max-width: 100%;
        }
        .course-number {
          color: $app-primary;
          .points-to-be-earned {
            font-size: 12px;
            color: #737373;
          }
          .points-to-be-earned-left {
            padding-right: 1px;
          }
          .points-to-be-earned-right {
            padding-left: 2px;
          }
        }
      }
    }

    .description-content {
      .ql-container.ql-snow {
        border: none !important;
      }
      .ql-editor {
        padding: 0;
      }
    }
  }

  .event-detail-reminder {
    padding: 0 24px 0 24px;

    .seperation-line {
      border-top: 1px solid $text-secondary;
      margin: 25px 0 24px 0;
    }
    .reminder-heading {
      margin-bottom: 16px;

      .reminder-heading-right {
        all: unset;
        cursor: pointer;
        padding: 7px 16px 7px 16px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;

        .attachment-text {
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          color: $app-primary;
          margin-right: 4px;
        }
      }

      .inactive {
        cursor: default;
        .attachment-text {
          color: $text-secondary;
          margin-right: 4px;
        }
        img {
          filter: grayscale(100%);
          opacity: 0.4;
        }
      }
    }
    .ql-container.ql-snow {
      border: none !important;
    }
    .ql-editor {
      padding: 0;
    }
  }
}

@media screen and (max-width: 576px) {
  .event-detail-body {
    .event-detail-image {
      border-radius: 10px;
      padding-bottom: 36px;
    }

    .course-detail-info {
      .course-detail-info-body {
        .course-schedule {
          max-width: 100%;
          font-size: 14px;

          .course-number {
            .points-to-be-earned {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
