@import 'theme';

.my-card{
  border-radius: 10px;
  cursor: pointer;
  @include cardShadow();
  padding: 24px 24px 16px 24px;
  line-height: 21px;
  width: 332px;
  background-color: white;

  .title{
    display: flex;
    margin-bottom: 16px;
    margin-right: 8px;
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    align-items: center;
  }

  .title-text{
    margin-left: 12px;
    display: inline-block;
    max-width: calc(100% - 3px - 12px);
  }

  .date{
    margin-bottom: 12px;
    white-space: nowrap;
  }

  .detail{
    padding-bottom: 8px;
  }

  .booking-status{
    background: #92CA62;
    padding: 8px 24px;
    border-radius: 20px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.07px;
    white-space: nowrap;

    @media screen and (max-width: 576px) {
      padding: 5px 16px;
      font-size: 10px;
    }
  }

  .booking-status.pending{
    background: $text-warning;

  }

  .placeholder-glow{
    width: 100%;
    display: block;
    span{
      height: 100%;
      width: 100%;
    }
  }

  .image-div{
    position: absolute;
    right: 16px;
    bottom: 16px;
  }

  .my-event-info-time-placeholder{
    width: 150px;
  }
  .my-event-info-date-placeholder{
    width: 150px;
  }
}

