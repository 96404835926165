@import 'theme';

.event-application-page {
  padding-bottom: 0 !important;
  .event-application-breadcrumb {
    margin-bottom: 36px;
  }
  .event-application-body {
    margin-top: 36px;
    // min-height: calc(100vh - 359px);
  }

  .plz-double-check {
    margin-top: 36px;
    margin-bottom: 16px;
  }

  .congrats-success {
    margin-top: 36px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .congrats-icon {
      margin-bottom: 24px;
    }

    .congrats-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 40px;
      color: $app-primary;
    }

    .bottom-congrats-text {
      margin-bottom: 24px;
    }
  }

  .seperation-line {
    margin: 24px 0 24px 0;
  }

  .please-show-QR {
    margin-bottom: 32px;
    color: $app-primary;
  }

  .QR-swiper-container {
    .swiper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .swiper-slide {
      transform: scale(0.8);
      align-self: center;
    }

    .swiper-slide-active {
      transform: scale(1);
    }
  }
  .application-finished-reminder-heading {
    color: $app-primary;
    font-weight: 600;
    line-height: 22px;
    font-size: 16px;
  }

  .application-finished-reminder-container {
    margin: 16px 0 16px 0;
    background-color: white;
    border-radius: 10px;
    @include cardShadow();
    padding: 16px 0px 16px 0px;
    label {
      cursor: pointer;
    }
    .application-finished-reminder-dropdown {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 24px 0px 24px;

      button {
        all: unset;
        cursor: pointer;
      }
    }
    .application-finished-reminder-content {
      padding: 16px 24px 0 24px;

      .ql-editor {
        padding: 0;
      }
    }
    .ql-container.ql-snow {
      border: none !important;
    }
  }

  .event-application-bottom {
    padding: 24px 60px 24px 60px;
    // @include bottomPartShadow();
    // border-radius: 10px 10px 0 0;
    // background-color: white;
    // z-index: 10;
    // position: sticky;
    // bottom: 0px;

    .event-application-bottom-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prev-button {
        background-color: white;
      }

      button,
      a {
        all: unset;
        cursor: pointer;
        color: $app-primary;
        border-radius: 10px;
        padding: 9px 0;
        height: 24.5px;
        width: 158px;
        font-weight: 600;
        line-height: 24.52px;
        font-size: 18px;
        text-align: center;
      }

      .prev-button {
        border: 1px solid #f4ecff;
      }

      .confirm-button {
        background: linear-gradient(
          to right,
          #ede3f7 0%,
          #fdf9ff 50%,
          #f1eaf9 100%
        );
      }
      .submit-button {
        background: linear-gradient(
          to right,
          #ede3f7 0%,
          #fdf9ff 50%,
          #f1eaf9 100%
        );
      }
    }
    .finish-page-bottom {
      display: flex;
      justify-content: center;
      .finish-button {
        background: linear-gradient(
          to right,
          #ede3f7 0%,
          #fdf9ff 50%,
          #f1eaf9 100%
        );
        max-width: 372px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .event-application-page {
    margin-top: 16px;
    padding-bottom: 16px !important;
    .stepper {
      position: fixed;
      top: $mobile-header-padding-top;
      left: 0;
      width: 100vw;
      z-index: 999;
      padding: 24px 40px;
      background-color: white;
    }
    .event-application-bottom {
      // position: fixed;
      // bottom: 0;
      // left: 0;
      // width: 100vw;
      padding: 12px 16px;
      .event-application-bottom-buttons {
        a,
        button {
          padding: 9px 0;
          width: 132px;
        }
        .finish-button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
