.page-loading-container {
  grid-column: 1/3;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sv-logo {
    width: 150px;
    height: 150px;
  }
}
