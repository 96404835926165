@import 'theme';
.event-application-payment-method-card{
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 24px;
  margin-top: 24px;

  label {
    cursor: pointer;
  }

  .payment-method-heading{
    margin-bottom: 40px;
  }

  .payment-method{
    margin-top: 24px;
    display: flex;
    align-items: center;

    .payment-method-radio{
      margin-right: 12px;
    }

    .payment-method-text{
      margin-left: 8px;
    }
  }
}
