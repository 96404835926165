@import 'theme';

.avatar {
  // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
}

.top-one-info {
  position: absolute;
  left: calc(50% - (200px / 2));
  top: 36px;
  text-align: center;
  width: 200px !important;
}

.prize {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}

.prize-first{
  aspect-ratio: 49/56;
}

.prize-other{
  aspect-ratio: 50/48;
}

.top-three-info {
  width: 172px;
  overflow: hidden;
  .top-three-text {
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
  }

  .name-placeholder {
    span {
      margin-bottom: 16px;
      width: 100%;
      height: 25px;
    }
  }

  .title-placeholder {
    span {
      margin-bottom: 8px;
      width: 100%;
      height: 22px;
    }
  }

  .amount-placeholder {
    span {
      width: 100%;
      height: 33px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-one-info {
    left: calc(50% - (110px / 2));
    top: 22px;
    width: 110px !important;
  }

  .top-three-info {
    width: 85px;

    .name-placeholder {
      span {
        height: 14px;
      }
    }

    .title-placeholder {
      span {
        height: 9px;
      }
    }

    .amount-placeholder {
      span {
        height: 14px;
      }
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 769px) {
  .top-one-info {
    left: calc(50% - (150px / 2));
    top: 30px;
    width: 150px !important;
  }

  .top-three-info {
    width: 130px;

    .name-placeholder {
      span {
        height: 20px;
      }
    }

    .title-placeholder {
      span {
        height: 15px;
      }
    }

    .amount-placeholder {
      span {
        height: 24px;
      }
    }
  }
}

