@import 'theme';

.toggle-nav-title-div {
  position: relative;
  white-space: nowrap;

  .title-container {
    transition: all 0.5s ease;

    .item-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &.tab {
        flex: 1;
      }

      &.filter {
        width: fit-content;
        padding: 0px 12px;
      }

      .button-div {
        text-align: center;
        transition: 0.5s ease-in-out;

        .shared-title-div {
          display: flex;
          justify-content: center;
          align-items: flex-end;

          .icon {
            padding-right: 4px;
          }
        }

        .title-div {
          font-weight: 600;
          color: $text-secondary;
          transition: 0.5s ease-in-out;
        }
      }

      .tab-bar {
        display: none;
      }

      .filter-bar.active {
        width: 14px;
        height: 2px;
        background-color: $app-secondary;
      }
    }

    .title{
      white-space: nowrap;
    }


    .title-active {
      font-weight: 600;
    }
  }

  .btn-active-bar {
    border-radius: 10px;
    position: absolute;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .bar {
      width: 100%;
      height: 2px;
      background-color: $app-secondary;
    }
  }
}
