@import 'theme';

.payment-record-container {
  // height: calc(100vh - 70px - 100px);
  .outstanding-amount-card-container {
    margin-top: 24px;
    // position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .payment-record-container {
    .outstanding-amount-card-container {
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }
}
