@import 'theme';

button.button {
  border: unset;
  width: 100%;
  padding: 12px;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  overflow: hidden;
  min-height: 44px;
  background-color: rgba(135, 95, 192, 0.3);
  background: url('../../images/btn-texture.png');
  opacity: 1;
  // background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 145% 145%;
  color: $app-primary;
  font-weight: 600;

  .button-content {
    // color: $app-primary;
    opacity: 1;
    z-index: 2;
  }



}
