@import 'theme';

.statement-row-button {
  all: unset;
  width: 100%;
  background-color: white;
  margin-bottom: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);

  .statement-row-container {
    padding: 12px 24px;
    width: 100%;
    justify-content: space-between;

    .pay-text {
      margin-top: 5px;
    }
    img {
      transform: rotate(90deg);
    }
  }

  .statement-row-list-item-title {
    margin-left: 24px;
  }
  
}

.fee-item-container {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d9d9d9;

  .fee-date {
    margin-bottom: 16px;
  }

  .fee-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
}