@import 'theme';

.please-show-QR {
  margin-bottom: 32px;
  color: $app-primary;
}

.please-show-ID {
  color: $text-warning;
  padding: 16px 24px 16px 24px;
  background-color: white;
  border-radius: 10px;
  @include cardShadow();
  margin-bottom: 24px;
}

.event-application-detail-seperation-line {
  margin: 24px 0 24px 0;
}

.QR-swiper-container {
  margin-bottom: 24px;
  .swiper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-slide {
    transform: scale(0.8);
    align-self: center;
  }

  .swiper-slide-active {
    transform: scale(1);
  }
}
