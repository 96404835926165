@import 'theme';

.empty-result-page {
  .empty-result-body {
    .empty-result-icon {
      max-width: 120px;
      max-height: 120px;
    }
    .empty-result-text {
      margin-top: 36px;
      margin-bottom: 44px;
      font-size: 28px;
      color: $text-primary;
      font-weight: 900;
      line-height: 38.14px;
    }
  }
}

.empty-result-page-with-tabbar {
  height: calc(824px - 107px);
}

@media screen and (max-width: 576px) {
  .empty-result-page {
    .empty-result-body {
      .empty-result-icon {
        max-width: 90px;
        max-height: 90px;
      }
      .empty-result-text {
        margin-top: 36px;
        margin-bottom: 60px;
        font-size: 22px;
        color: $text-primary;
        font-weight: 900;
        line-height: 38.14px;
      }
    }
  }
  .empty-result-page-with-tabbar {
    height: calc(
      100vh - $mobile-header-padding-top - $mobile-page-padding-bottom - 49px
    );
  }
}
