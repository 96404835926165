@import 'theme';

.banner-card {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  border-radius: 15px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @include cardShadow();
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  position: relative;
  background-size: cover;

  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    flex: 1;
  }

  .overlay-layer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 16px;
    height: 40%;
    background: linear-gradient(
      0deg,
      rgba(34, 31, 31, 0.3) 59.91%,
      rgba(40, 35, 35, 0) 90.62%
    );
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    // overflow: hidden;

    .title-text {
      line-height: normal;
      text-align: center;
      white-space: pre-wrap;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {

}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
