@import './Shadow.scss';

@mixin customBtn {
  border: none;
  background: none;
  box-shadow: none;
}

@mixin purpleButton {
  all: unset;
  cursor: pointer;
  background: linear-gradient(to right, #ede3f7 0%, #fdf9ff 50%, #f1eaf9 100%);
  text-align: center;
  color: $app-primary;
  border-radius: 10px;
  @include cardShadow();
}
