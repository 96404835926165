@import 'theme';

.info-card {
  line-height: 21px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  .info-card-image-container {
    height: 140px;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    aspect-ratio: 1/1;
    .info-card-image {
      object-fit: cover;
      height: 140px;
      aspect-ratio: 1/1;
    }
  }

  .info-card-container {
    overflow: hidden;
    padding: 24px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    max-width: calc(100% - 140px);

    .left-side-content {
      max-width: calc(100% - 44px);
      white-space: pre-wrap;
      overflow: hidden;
      flex-grow: 1;

      .title-div {
        position: relative;
        margin-bottom: 16px;
        width: 100%;

        .title-text {
          flex-wrap: wrap;
          div {
            width: auto;
          }
        }

        &.preagent {
          margin-top: 11px;
          margin-bottom: 5px;
        }

        .title {
          padding-left: 10px;
          max-width: calc(100% - 3px - 10px);
        }

        .title-no-padding {
          padding: 0;
        }

        .pen-edit-icon-btn {
          padding-left: 10px;
          height: 20px;
          cursor: pointer;
        }
      }

      .title-div-no-margin {
        margin: 0;
      }

      .date-div {
        margin-bottom: 12px;
      }

      .preagent-content-div {
        display: flex;
        align-items: center;

        .telephone-icon {
          margin-right: 12px;
        }

        .email-div {
          letter-spacing: 0.03em;
        }
      }
    }

    .right-side-content {
      width: 1px;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      right: 44px;
      bottom: 16px;
      .image-div {
        cursor: pointer;
        // width: 60px;

        img {
          margin-bottom: 7px;
        }
      }
    }

    .right-side-content-score {
      display: flex;
      align-items: center;
      .membership-point-div {
        display: flex;
        align-items: center;
        // position: absolute;
        right: 36px;
        bottom: 20px;

        img,
        .point {
          margin-left: 4px;
        }
      }
    }
  }
  .info-card-container-score {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .info-card {
    height: unset;
    flex-direction: column;
    .info-card-image-container {
      .info-card-image {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
      }
    }

    .info-card-container {
      max-width: none;
      padding: 16px;
      .left-side-content {
        max-width: calc(100% - 56px);
      }
      .right-side-content {
        .image-div {
          cursor: pointer;
          top: calc(50% - 33px) !important;
          img {
            margin-bottom: 7px;
          }
        }
      }

      .right-side-content-score {
        .membership-point-div {
          img,
          .point {
            margin-left: 4px;
          }

          img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }
}
