@import 'theme';

.pop-up{
  width: 100%;
  .pop-up-content{
    text-align: center;
    margin-bottom: 24px;
  }
  .pop-up-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    button{
      all: unset;
      cursor: pointer;
      color: $app-primary;
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      width: 160px;
      height: 40px;
      text-align: center;
    }
    .pop-up-buttons-cancel{
      border: 1px solid #F4ECFF;
    }
    .pop-up-buttons-confirm{
      background: linear-gradient(to right, #ede3f7 0%, #fdf9ff 50%, #f1eaf9 100%);
    }
  }
}
