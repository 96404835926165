@import 'theme';

.banner-slider {
  padding-bottom: 35px !important;
  overflow-x: hidden;

  .swiper-wrapper {


    .swiper-slide {
      transition: all 0.2s ease-in;

      &.swiper-slide-next {
        transform: scale(0.65);
        //filter: blur(1px);
      }

      &.swiper-slide-prev {
        transform: scale(0.65);
        //filter: blur(1px);
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $app-secondary;
  }

}

.loading-slide-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 19px;

  .loading-banner-card {
    flex: 1;
    height: 200px;
    margin: 8px;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (max-width: 576px) {
      height: 130px;
    }

    span.placeholder {
      height: 100%;
    }
  }
}
