@import 'theme';

.layout-container {
  width: 100vw;
  background-color: $app-bg;
  overflow-y: auto;

  @media screen and (max-width: 576px) {
    // overflow-y: unset;
    // max-height: 100vh;
  }

  &.auth {
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    height: 100vh;
  }

  &.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    // overflow-x: hidden;

    .content {
      width: 100%;
      z-index: 1;
      position: relative;

      @media screen and (max-width: 576px) {
        // overflow-y: unset;
        padding-bottom: $mobile-page-padding-bottom;
        max-height: 100%;
      }

      nav.navbar {
        height: 90px;
        background-color: #ffffff;
        @include cardShadow();
        z-index: 100;

        position: sticky;
        top: 0;

        @media (max-width: 576px) {
          padding: 0 20px;
        }
      }

      .content-container {
        width: calc(100% - $side-bar-width);
        margin-left: 0px;
        position: relative;
        min-height: calc(100vh - $nav-bar-height);

        @media screen and (min-width: 992px) {
          margin-left: $content-container-margin;
        }
        @media screen and (max-width: 576px) {
          min-height: calc(100vh - $mobile-page-padding-bottom);
        }
      }
    }
  }

  .auth-bg-img {
    width: 50vw;
    height: 100%;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .auth-content {
    width: 100%;
    &.desktop {
      width: 50vw;
    }

    z-index: 1;
  }

  .bg-graphic-v1 {
    position: absolute;
    left: -1.46%;
    top: -10.39%;
    max-width: 300px;
    width: calc(100vw * 0.25);
    height: auto;
  }

  .bg-graphic-v2 {
    position: absolute;
    left: 35.47%;
    top: -2.85%;
    max-width: 560px;
    width: calc(100vw * 0.46);
    height: auto;
  }

  .bg-graphic-v3 {
    position: absolute;
    right: 0%;
    top: 0%;
    max-width: $side-bar-width;
    width: calc(100vw * 0.23);
    height: auto;
  }

  .bg-graphic-v4 {
    position: absolute;
    bottom: 0px;
    right: 0px;
    max-width: 100px;
    width: calc(100vw * 0.12);
    height: auto;
  }

  .bg-graphic-v5 {
    position: absolute;
    left: 0%;
    bottom: 0%;
    transform: matrix(0.06, -1, 1, -0.09, 0, 0);
    max-width: 300px;
    width: calc(100vw * 0.25);
    height: auto;
  }
}

