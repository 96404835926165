@import 'theme';

.side-bar {
  width: 276px;
  //max-height: calc(100vh - 124px);
  min-height: 700px;
  background-color: #ffffff;
  margin-top: 24px;
  @include cardShadow();
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  top: -130px;


  .nav-item {
    width: 100%;
    padding: 12px 46px;
    text-decoration: none;
    border-left: 3px solid transparent;

    .nav-item-title {
      padding-left: 25px;
      @include typography(18px, bold);
      color: $app-primary-dark;
      text-decoration: none;
    }

    &.active {
      background-color: #FFFAF2;
      border-left: 3px solid $app-secondary;

      .nav-item-title {
        color: $app-secondary;
      }
    }


  }
}
