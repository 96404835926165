.info-button-container {
  width: 100%;
  height: 118px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    height: 98px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
  }

  .info-button-icon {
    @media screen and (max-width: 1200px) {
      height: 48px;
    }
  }
}
