@import 'theme';

.stripe-checkout-form {
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .stripe-form {
    width: 100%;
  }

  .book-now-button{
    all: unset;
    cursor: pointer;
    // padding: 9px 52px;
    background: linear-gradient(to right, #ede3f7 0%, #fdf9ff 50%, #f1eaf9 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $app-primary;
    font-weight: 600;
    line-height: 25px;
    font-size: 18px;
    border-radius: 10px;
  }



  @media screen and (max-width: 1350px) {
      .stripe-checkout-form {
          margin-top: 0;
      }

      .stripe-checkout-button {
          display: none;
      }
  }

  .err-text {
      font-size: 14px;
      color: #dc3545;
  }

}

