@import 'theme';

.drive-mobile-bottom {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  border-radius: 10px 10px 0 0;
  @include bottomPartShadow();
  width: 100vw;
  padding: 40px;
  background-color: white;

  .drive-mobile-bottom-public {
    .show-selected-number-div {
      gap: 8px;
    }
    .selected-div {
      .add-to-my-folder-div {
        img {
          margin-right: 12px;
        }
      }
    }
  }

  .drive-mobile-bottom-private {
    .show-selected-number-div {
      gap: 8px;
    }
    .selected-div {
      .private-selected-div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .private-move-folder-div,
        .private-rename-folder-div,
        .private-delete-seleced-div {
          display: flex;
          margin: 0;
          gap: 12px;
          div {
            width: auto;
          }
        }
      }
    }
  }
}
