.drive-move-folder-dialog {
  width: 100%;
  position: relative;

  .title-div {
    position: relative;
    margin-bottom: 10px;
    .title-bottom-bar {
      width: 20px;
      height: 4px;
      position: absolute;
      background: #ffba5e;
      bottom: -8px;
      left: 1px;
    }
  }
  .content-list-item {
    overflow-y: auto;
    height: 500px;
    margin-bottom: 50px;
    position: relative;

    .empty-page-container {
      top: 50%;
    }
  }

  .previous-page-icon {
    position: absolute;
    transform: rotate(180deg);
    top: -60px;
    left: 0;
  }

  .button-div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .drive-move-folder-dialog{
    .content-list-item{
      height: 300px;
    }
  }
}
