@import 'theme';

.payment-due-modal-dialog{
  max-width: 100vw;
}

.payment-due-modal-content{
  background-color: transparent;
  border-width: 0px;
  align-items: center;

  .payment-due-modal {
    display: flex;
    flex-direction: column;
  
    .payment-due-close-button{
      all: unset;
      cursor: pointer;
      align-self: flex-end;
      margin-bottom: 10px;
      opacity: 0.7;
      
      img{
        height: 30px;
        width: 30px;
      }
      &:hover {
        opacity: 1;
      }

      &:active {
        outline: none;
      }
    }
    
    .payment-due-modal-content-box{
      background-color: white;
      border-radius: 20px;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 36px 84px;

      .payment-due-title{
        width: fit-content;
        font-weight: 600;
        font-size: 16px;
        color: $text-primary;
        white-space: pre-line;
        line-height: 24px;
        text-align: center;
        margin-top: 24px;
        margin-bottom: 16px;
      }
    }
  }
}


