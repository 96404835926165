@import 'theme';

.success-page {
  width: 900px;
  height: 100%;
  max-height: 910px;
  max-width: 100%;

  // display: flex;
  // justify-content: center;
  // align-items: flex-end;
  .success-content-wrapper {
    text-align: center;

    .image-div {
      margin-bottom: 46px;
    }

    .text-wrapper {
      letter-spacing: 0.03em;
      margin-bottom: 36px;
    }

    .email-wrapper {
      letter-spacing: 0.03em;
      margin-bottom: 143px;
    }

    .button-wrapper {
      width: 450px;
      margin: auto;
    }
  }
}

.userfee-page-conatiner {
  .success-page {
    margin-top: 20vh;
  }
}

@media screen and (max-width: 576px) {
  .success-page {
    .success-content-wrapper {
      .email-wrapper {
        margin: 0;
      }
      .button-wrapper {
        width: 100vw;
        padding: 40px;
        position: fixed;
        background-color: white;
        border-radius: 10px 10px 0 0;
        left: 0;
        bottom: 0;
        margin: auto;
        @include bottomPartShadow();
      }
    }
  }
}
