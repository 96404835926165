@import 'theme';

.empty-page-container {
  // position: absolute;
  margin-left: auto;
  margin-right: auto;
  // left: 0;
  // right: 0;
  text-align: center;
  // top: 100%;

  .image-div {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 576px) {
  .empty-page-container{
    padding-top: $mobile-header-padding-top;
    grid-column: 1/3;
  }
}
