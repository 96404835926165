@import 'theme';
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    margin-bottom: 0;
    margin-top: 10px;

    li {
      width: 36px;
      height: 36px;
      margin: 0 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        color: $app-primary;
        border: 1px solid $app-primary;
        border-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: none !important;
        outline: none !important;
        outline-offset: none !important;
        text-align: center;
        padding: 6px 0 6px 0;
        // padding: 0.375rem 0.75rem 0.375rem 0.75rem;
      }
      span {
        padding: 6px 0 6px 0;
      }

      .page-link {
        width: 36px;
        height: 36px;
        position: relative;
        img {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          top: 5px;
          left: 0;
          right: 0;
        }
      }
    }

    .arrow-left {
      .page-link {
        img {
          transform: rotate(180deg);
        }
        &:focus {
          transition: 0.5s ease-in-out;
          background-color: #fff;
        }
      }
    }
    .arrow-right {
      .page-link {
        &:focus {
          transition: 0.5s ease-in-out;
          background-color: #fff;
        }
      }
    }

    .double-arrow-left {
      .page-link {
        img {
          transform: rotate(180deg);
        }
        &:focus {
          transition: 0.5s ease-in-out;
          background-color: #fff;
        }
      }
    }

    .double-arrow-right-disabled {
      .page-link {
        img {
          transform: rotate(180deg);
        }
        &:focus {
          transition: 0.5s ease-in-out;
          background-color: #fff;
        }
      }
    }

    .active {
      .page-link {
        background: #6b63c4;
        border: 1px solid #6b63c4;
        border-radius: 10px;
      }
    }

    .page-item:first-child .page-link,
    .page-item:last-child .page-link,
    .page-item:nth-child(2) .page-link,
    .page-item:nth-last-child(2) .page-link {
      border: 1px solid #d9d9d9;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

@media screen and (max-width: 576px) {
  .pagination-container {
    ul {
      li {
        // padding: 4px 0 4px 0;
        margin: 4px;
      }
    }
  }
}
