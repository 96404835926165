@import 'theme';

.stripe-form-container{
  width: 100%;
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  margin-top: 24px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  .payment-method-heading{
    line-height: 24.52px;
    margin-bottom: 20px;
  }
  .stripe-form{
    position: relative;
    margin-bottom: 10px;
  }
}
