@import 'theme';

.drive-public-page-container {
  position: relative;

  .drive-public-breadcrumb {
    margin-bottom: 24px;
  }

  .function-div {
    margin-bottom: 24px;
    padding: 0px 20px;
  }

  .drive-public-filter-dropdown {
    text-align: end;
    margin-right: 24px;
  }

  .file-item-div {
    margin-top: 23px;
  }
}

@media screen and (max-width: 576px) {
  .drive-public-page-container {
    .function-div {
      padding: 0;
      margin: 0;
      // max-width: 200px;
    }
    // .drive-public-breadcrumb{
    //   //padding-left: 24px;
    // }
    .file-item-div {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 24px;
      .drive-list-item-mobile {
        justify-self: center;
      }
    }

    .function-div {
      padding-left: 0;
    }
  }
}
