@import 'theme';

.mobile-bottom-button {
  background-color: white;
  position: fixed;
  padding: 24px;
  border-radius: 10px 10px 0 0;
  width: 100vw;
  @include bottomPartShadow();
  left: 0;
  bottom: 0;
  z-index: 9999;
}
