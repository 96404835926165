.news-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 8px 0px;
  position: relative;

  .news-card-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 100%;

    .news-image-container {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16/9;
      }
    }


    .news-info-container {
      padding: 16px;

      @media screen and (max-width: 576px) {
        padding: 12px;
      }

      .news-title {
        position: relative;
      }

      .news-preview-text-container {
        .quill.news-rice-text {
          position: relative;
          color: #737373;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;

          .ql-container {
            border: none;

            .ql-editor {
              padding: 0px;
              height: 35px;
              overflow-y: hidden;

              & > * {
                cursor: pointer;
              }
            }

          }
        }
      }

      .text-date {
        width: 100%;
        text-align: right;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        color: #D9D9D9;
      }
    }
  }

}
