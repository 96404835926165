@import 'theme';

$container-width-xxl: 1320px;
$container-width-xl: 1140px;
$container-width-lg: 960px;
$container-width-md: 720px;
$container-width-sm: 540px;

.survey-page {
  padding-bottom: 0 !important;
  background-color: transparent;
  width: 100%;
  position: relative;

  .survey-page-breadcrumb{
    margin-bottom: 36px;
  }

  .survey-card {
    @include cardShadow();
    width: 100%;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 30px;
    overflow: hidden;

    .survey-image-container{
      // margin-bottom: 30px;
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 10px 10px 10px 10px;
      overflow: visible;

      .swiper{
        height: 100%;
        position: relative;
        overflow: visible;
      }

        img{
          width: 100%;
          height: 100%;
          z-index: 5;
          object-fit: cover;
          border-radius: 10px;
        }
      .swiper-pagination-bullet{
        --swiper-pagination-bullet-size: 6px !important;
      }

      .swiper-pagination-bullet-active{
        --swiper-pagination-bullet-size: 10px !important;
      }
    }

    .swiper-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      --swiper-pagination-color: #ffba5e;
      z-index: 50;
      bottom: -24px !important;
      position: absolute;
    }


    .survey-description-container {
      padding: 20px 24px 24px 24px;


      .survey-rice-text {
        .ql-container.ql-snow {
          border: none;

          .ql-editor {
            padding: 0;
          }
        }
      }
    }

    .card-wrapper {
      padding: 24px;

      .form-group {

        label {
          color: $app-primary-dark;
        }
      }
    }

    .survey-success-page {
      width: 100%;
      min-height: 350px;
      padding: 48px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .success-content-container {

        .ql-container.ql-snow {
          border: none !important;
        }
      }
    }
  }

  .button-container-row {
    // background-color: white;
    // position: sticky;
    // bottom: 0px;
    padding: 24px 60px;
    // height: 92px;
    // box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.04);
    // border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

  }

}
@media screen and (max-width: 576px) {
  .survey-page{
    padding-bottom: 16px !important;
    .survey-page-breadcrumb{
      margin-bottom: 24px;
    }
    .button-container-row {
      padding: 0 36px;
      // position: fixed;
      // width: 100vw;
      // left: 0;
      // bottom: 0;

    }
  }
}



