@import 'theme';

.custom-checkbox-container{
  height: 16px;
  width: 16px;
  white-space: nowrap;

  .custom-checkbox-before{
    opacity: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: 50;
    cursor: pointer;
  }

  .custom-checkbox-after{
    border: 2px solid #CBCBD0;
    box-shadow: 0px 0px 0px 0.5px #CBCBD0;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
  }

  .custom-checkbox-before:active + .custom-checkbox-after{
    background-color: rgba(128, 128, 128, 0.2);
  }

  .custom-checkbox-before:active + .custom-checkbox-after::after{
    background-color: $app-primary-dark;
  }

  .custom-checkbox-before:checked:active + .custom-checkbox-after{
    box-shadow: 0px 0px 0px 0.5px $app-primary-dark;
  }

  .custom-checkbox-after::after{
    content: '';
    width: 126px;
    height: 126px;
    background-color: $app-primary;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 0;
    transition: opacity 0.00s;
  }

  .custom-checkbox-before:checked + .custom-checkbox-after {
    border: none;
    box-shadow: none;
  }

  .custom-checkbox-before:checked + .custom-checkbox-after::after {
    opacity: 1;
  }
  .custom-checkbox-before:checked + .custom-checkbox-after .checkmark_stem,  .custom-checkbox-before:checked + .custom-checkbox-after .checkmark_kick {
    opacity: 1;
    transition: opacity 0.2s;
  }

  .checkmark_stem {
    white-space: nowrap;
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: white;
    left: 7.5px;
    top: 3px;
    z-index: 10;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.2s;
  }

  .checkmark_kick {
    white-space: nowrap;
    position: absolute;
    width: 3px;
    height: 2px;
    background-color: white;
    z-index: 10;
    left: 3.5px;
    top: 8px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity 0.2s;
  }
}
