@import 'theme';

.event-application-guest-info-card {
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 24px;

  .guest-info-heading {
    padding: 6px 24px 5px 24px;
    line-height: 24.52px;
  }

  .guest-info-subheading {
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    margin-top: 16px;
  }

  .input-field {
    position: relative;
    width: 80%;

    .guest-input {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 19.07px;
      width: 100%;
      height: 40px;
      border: 1px solid $text-secondary;
      border-radius: 10px;
    }
    .cancel-button {
      position: absolute;
      right: 11px;
      cursor: pointer;
      top: calc(50% - 10px + 4px);
    }
  }

  label::after {
    display: inline;
    content: '*';
    color: red;
  }

  .name-and-email-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    .guest-name {
      width: 45%;
    }

    .guest-email {
      width: 45%;
    }
  }

  .guest-contact {
    margin-top: 16px;
    width: 45%;
    padding-right: 0px;
  }
}

@media screen and (max-width: 576px) {
  .event-application-guest-info-card {
    .event-application-guest-info-card-body-mobile {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      .guest-name {
        width: 100%;
      }
      .guest-contact {
        width: 100%;
        margin: 0;
      }
      .guest-email {
        width: 100%;
      }
    }
  }
}
