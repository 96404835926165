@import 'theme';

.section-header {
  border: unset;
  width: fit-content;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: linear-gradient(to right, #fce7ce 0%, #fcf6eb 50%, #fcf2e1 100%);
  display: flex;
  gap: 12px;
  align-items: center;
  mix-blend-mode: color;

  .section-header-title {
    width: fit-content;
    font-weight: 600;
    font-size: 20px;
    color: $header-title;
    white-space: nowrap;
    line-height: 27.24px;
  }

  .section-header-icon {
    img{
      height: 24px;
    }
  }
}
