.search-bar-container {
  .search-icon-div {
    .search-input-field {
      position: relative;

      input {
        padding: 0.5rem 3rem;
        border: none;
        box-shadow: none;
        &:focus {
          border: none;
          box-shadow: none;
          background-color: transparent;
        }
      }

      .search-cancel-desktop {
        position: absolute;
        top: calc(50% - 12px);
        right: 16px;
      }

      .search-icon-grey {
        position: absolute;
        top: 9px;
        left: 13px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .search-bar-container {
    .search-icon-div {
      .search-input-field {
        position: relative;

        input {
          padding: 6px 24px 6px 48px;
          border: none;
          box-shadow: none;
          &:focus {
            border: none;
            box-shadow: none;
            background-color: transparent;
          }
        }

        .search-icon-grey {
          position: absolute;
          top: 5px;
          left: 13px;
        }

        .search-cancel {
          position: absolute;
          top: calc(50% - 12px);
          right: 12px;
        }
      }
    }
  }
  .mobile-header-search-bar-container {
    width: 100%;
    padding-left: 14px;
    .search-icon-div {
      .mobile-header-search-input-field {
        padding: 0;
        border: none;
        box-shadow: none;
        input {
          padding: 0 24px 0 calc(24px + 13px + 16px);
        }
        .search-icon-grey {
          position: absolute;
          top: 0;
          left: 13px;
        }
        .search-cancel {
          position: absolute;
          top: calc(50% - 12px);
          right: 0;
        }
      }
    }
  }
}
