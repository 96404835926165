@import 'theme';

.event-application-payment-options-card{
  padding: 24px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 24px;

  label {
    cursor: pointer;
  }

  .payment-options-heading{
    line-height: 24.52px;
  }

  .payment-options-subheading{
    font-weight: 600;
    font-size: 18px;
    line-height: 24.52px;
    color: #818181;
    margin-top: 8px;
    margin-bottom: -2px;
  }

  .payment-option{
    display: flex;
    align-items: center;
    margin-top: 18px;
    .payment-radio{
      margin-right: 12px;
    }
  }
}
