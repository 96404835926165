@import 'theme/Typograpgy.scss';

.membership-card {
  position: relative;
  min-width: 240px;
  width: 100%;
  aspect-ratio: 500/270;
  // background: rgba(255, 255, 255, 0.03);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .card-vector {
    object-fit: contain;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
  }

  .gaussian-layer {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(40px);
    border-radius: 8px;
  }

  .card-surface-layer {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 8px;
    background: linear-gradient(
      106.15deg,
      #b00000 6.17%,
      #fa738d 61.15%,
      #b00000 113.37%
    );

    &.ruby {
      background: linear-gradient(
        106.15deg,
        #b00000 6.17%,
        #fa738d 61.15%,
        #b00000 113.37%
      );
    }

    &.sapphire {
      background: linear-gradient(
        106.15deg,
        #093197 6.17%,
        #aad0ff 58.51%,
        #021ead 113.37%
      );
    }

    &.yellow-diamond {
      background: linear-gradient(
        106.15deg,
        #c28e08 6.17%,
        #fcd786 57.85%,
        #c28e08 112.05%
      );
    }

    &.diamond {
      background: linear-gradient(
        106.15deg,
        #bcf3ff 6.17%,
        #f2fdff 64.46%,
        #abefff 92.88%
      );
    }

    &.emerald {
      background: linear-gradient(
        106.15deg,
        #133f08 6.17%,
        #b1e28a 57.19%,
        #064e23 113.37%
      );
    }

    &.silver {
      background: linear-gradient(
        106.15deg,
        #d9d9d9 6.17%,
        rgba(0, 0, 0, 0.07) 56.52%,
        #414143 113.37%
      );
    }

    &.common {
      background: linear-gradient(
        106.15deg,
        #6b63c4 6.17%,
        rgba(107, 99, 196, 0.2) 56.52%,
        #c4bffe 113.37%
      );
    }
  }

  .member-info-layer {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    background-color: transparent;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .member-level-text {
      @include typography(12px, medium);
      color: #ffffff;
      text-align: right;
      width: 100%;

      &.diamond {
        color: #737373;
      }
    }
  }
}
