@import 'theme';

.score-board-container {
  width: 100%;
  padding-top: 20px;
  position: relative;

  .score-board-title {
    position: absolute;
    padding: 16px 48px !important;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0);
    margin: auto;
  }

  .score-board-card {
    margin-top: 33px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 60px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    min-height: 1100px;

    button {
      @include customBtn;
      padding: 0 36px;
      width: 144px;
    }

    .title-container {
      transition: all 0.5s ease;
      .title-div {
        font-weight: 400;
      }
      .title-active {
        font-weight: 800;
      }
    }

    .btn-active-bar {
      border-radius: 10px;
      width: 20px;
      border-bottom: 4px solid $app-secondary;
      position: absolute;
      bottom: -40%;
      transition: all 0.5s ease-in-out;
    }
  }
}

@media screen and (max-width: 1200px) and (min-width: 993px) {
  .score-board-container {
    button {
      @include customBtn;
      font-size: 16px;
      padding: 0 !important;
      width: auto !important;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 769px) {
  .score-board-container {
    button {
      @include customBtn;
      font-size: 16px;
      padding: 0 !important;
      width: auto !important;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .score-board-container {
    .score-board-title{
      padding: 8px 36px !important;
      top: 32px;
      .title-text{
        white-space: nowrap;
      }
    }

    button {
      @include customBtn;
      font-size: 16px;
      padding: 0 !important;
      width: auto !important;
    }

    .score-board-card {
      padding: 36px 16px 24px 16px;
      min-height: 500px;
    }
  }
}

@media screen and (max-width: 576px) {
  .score-board-container {
    .score-board-title{
      padding: 8px 36px !important;
      top: 32px;
      .title-text{
        white-space: nowrap;
      }
    }

    button {
      @include customBtn;
      font-size: 12px;
      padding: 0 !important;
      width: auto !important;
    }

    .score-board-card {
      padding: 36px 16px 24px 16px;
      min-height: 500px;
    }
  }
}


@media screen and (max-width: 360px) {
  .score-board-container {

    .score-board-title{
      top: 32px;
      .title-text{
        white-space: nowrap;
      }
    }

    button {
      @include customBtn;
      font-size: 12px !important;
      padding: 0 !important;
      width: auto !important;
    }

    .score-board-card {
      padding: 36px 0 24px 0;
      min-height: 0;
    }
  }
}

@media screen and (max-width: 992px) and (min-width: 577px) {
}
