@import 'theme';

.disabled-QRcode-card-content {
  filter: blur(4px);
}

.QR-code-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.QRcode-card {
  padding: 17px 24px 17px 24px;
  @include cardShadow();
  border-radius: 10px;
  width: fit-content;
  background-color: white;

  .user-QR-code {
    img {
      margin-top: 19px;
      width: 300px;
    }
    .user-QR-code-text {
      font-size: 28px;
      margin: 36px 0;
    }
  }

  .QRcode-card-name {
    line-height: 22px;
  }

  .attendee-name {
    max-width: 210px;
  }

  .QRcode-card-expired {
    color: #f04c4d;
    margin-bottom: 8px;
  }

  .QRcode-container {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 16px;
    width: 220px;
  }

  .redeem {
    .redeem-text {
      line-height: 21.79px;
    }

    .redeem-code {
      line-height: 21.79px;
      color: $app-primary;
    }
  }
}

.user-QRcode-card {
  padding: 17px 120px;
}
@media screen and (max-width: 576px) {
  .QRcode-card {
    .user-QR-code {
      img {
        width: 148px;
      }
      .user-QR-code-text {
        font-size: 16px;
        margin: 16px 0;
      }
    }
  }
  .user-QRcode-card {
    padding: 17px 74px;
  }
}
